/* ---------------------------------------------
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
/* --------------------------------------------- */
@import 'variable';

@import 'mixins/spacing';
@import 'mixins/color';
@import 'mixins/font';
@import 'mixins/width';

@import 'grid/grid';

@import 'component/global';
@import 'component/heading';

@import 'component/header';
@import 'component/sidebar';
@import 'component/spinner';
@import 'component/card';
@import 'component/dashboard';

@import 'responsive';

@import "../styles/icons/material-design-iconic-font/css/materialdesignicons.min.css";

/* button:not(.mat-mdc-icon-button) {
    height: 40px !important;
} */

.form-group {
    margin-bottom: 15px;
}

.form-group>label {
    margin-bottom: 10px;
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #fff;
    margin: 0 10px;
}

.divider span {
    padding: 0 10px;
    font-size: 16px;
    color: #fff;
}

.mat-mdc-tab-body-content {
    overflow: hidden !important;
    padding: 15px;
}

.mat-mdc-raised-button.mat-unthemed[disabled][disabled] {
    --mdc-protected-button-disabled-container-color: rgb(255 255 255 / 50%);
    // --mdc-protected-button-disabled-label-text-color: rgb(255 255 255 / 54%);
    --mdc-protected-button-container-color: rgb(255 255 255 / 56%);
    // --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
    --mdc-protected-button-container-elevation: 0;
}

/* .mat-mdc-raised-button.mat-unthemed:not(:disabled) {
    color: var(--mdc-protected-button-label-text-color) !important;
} */

.mat-mdc-form-field-infix {
    min-height: auto !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 10px 0 !important;
}

.asterix:after {
    content: " *";
    color: red;
}

.mat-toolbar.mat-primary {
    background: #fff;
    // box-shadow: none;
}

.menu-icon {
    width: 30px;
}

.mat-mdc-list-base {
    --mdc-list-list-item-one-line-container-height: auto !important;
}

.mat-mdc-raised-button.mat-danger {
    --mdc-protected-button-container-color: #FF0000;
    --mdc-protected-button-label-text-color: #FFF;
}

#snav .mdc-list {
    margin-top: 20px;
}

.mat-mdc-menu-trigger {
    grid-gap: 15px;
}

.pointer {
    cursor: pointer;
}
