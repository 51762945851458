.preloader {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.7); /* Change solid white background to semi-transparent */
}

.spinner {
  width: 40px;
  height: 40px;
  top: 35%;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1976d2;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
      -webkit-transform: scale(0);
  }
  50% {
      -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
      transform: scale(0);
      -webkit-transform: scale(0);
  }
  50% {
      transform: scale(1);
      -webkit-transform: scale(1);
  }
}
