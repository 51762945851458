$f: 10;

@while $f <= 5000 {
  .w-p-#{$f} {
    width: 1px * $f !important;
  }

  .m-w-p-#{$f} {
    min-width: 1px * $f !important;
  }

  $f: $f + 2;
}