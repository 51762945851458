/*******************
 Topbar
*******************/
mat-toolbar {
    box-shadow: 1px 0px 10px rgba(105, 105, 105, 0.414);
    &.topbar {
      padding: 0px 15px;
    }
    .navbar-header {
      text-align: center;
      .navbar-brand {
        display: flex;
        line-height: 18px;
        align-items: center;
        .dark-logo {
          display: none;
        }
        b {
          margin-right: 5px;
        }
      }
    }

    .profile-pic {
      width: 40px !important;
      height: 40px !important;
      border-radius: 100%;
    }
  }
  /*******************
   Topbar
  *******************/
  .topbar {
    position: relative;
    z-index: 2;
  }

  .example-sidenav-container {
    flex: 1;
  }

  .example-sidenav-container {
    flex: 1 0 auto;
  }

  .initials {
    width: 40px; /* Same size as the previous profile picture */
    height: 40px;
    border-radius: 50%;
    background-color: #f44336; /* You can change the color */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px; /* Adjust size as needed */
    font-weight: bold;
    text-transform: uppercase; /* Makes sure initials are uppercase */
  }

